import React,
{
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

const CallSettingsContext = createContext({
  videoDeviceId: null,
  setVideoDeviceId: null,
  audioOutputDeviceId: null,
  setAudioOutputDeviceId: null,
  setMicrophoneId: null,
  microphoneId: null,
  cameraResolution: null,
  receivedVideoResolution: null,
  isCameraEnabled: false,
  isMicrophoneEnabled: false,
  setIsCameraEnabled: null,
  setIsMicrophoneEnabled: null,
})
export const useCallSettingsContext = () => useContext(CallSettingsContext)

export const CallSettingsProvider = ({ children }) => {
  const [isCameraEnabled, setIsCameraEnabled] = useState(false)
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true)

  const resolution = localStorage.getItem('resolution')
  const storedCameraResolution = resolution && JSON.parse(resolution)

  const receiveResolution = localStorage.getItem('receivedResolution')
  const storedReceivedResolution = receiveResolution && JSON.parse(receiveResolution)

  const storedVideoDeviceId = localStorage.getItem('defaultVideoDeviceId')
  const storedMicrophoneId = localStorage.getItem('defaultMicrophoneId')
  const storedAudioOutputDeviceId = localStorage.getItem('audioOutputDeviceId')

  const [outputCameraResolution, setOutputCameraResolution] = useState(storedCameraResolution)
  const [receivedVideoResolution, setReceivedVideoResolution] = useState(storedReceivedResolution)
  const [videoDeviceId, setVideoDeviceId] = useState(storedVideoDeviceId)
  const [microphoneId, setMicrophoneId] = useState(storedMicrophoneId)
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState(storedAudioOutputDeviceId)

  const setToLocalStorage = (itemName, value) => {
    localStorage.setItem(itemName, value)
    window.dispatchEvent(new Event('set-settings-to-storage'))
  }

  const setVideoDeviceIdToStorage = id => setToLocalStorage('defaultVideoDeviceId', id)
  const setMicrophoneIdToStorage = id => setToLocalStorage('defaultMicrophoneId', id)
  const setAudioOutputDeviceIdToStorage = id => setToLocalStorage('audioOutputDeviceId', id)
  const setReceivedResolutionToStorage = receivedResolution => setToLocalStorage(
    'receivedResolution', JSON.stringify(receivedResolution),
  )
  const setCameraResolutionToStorage = cameraResolution => setToLocalStorage(
    'resolution', JSON.stringify(cameraResolution),
  )

  const setDevices = useCallback(() => {
    const newResolution = localStorage.getItem('resolution')
    const newCameraResolution = newResolution && JSON.parse(newResolution)
    const newReceiveResolution = localStorage.getItem('receivedResolution')
    const newReceiveVideoResolution = newReceiveResolution && JSON.parse(newReceiveResolution)

    setOutputCameraResolution(newCameraResolution)
    setReceivedVideoResolution(newReceiveVideoResolution)

    setVideoDeviceId(localStorage.getItem('defaultVideoDeviceId'))
    setMicrophoneId(localStorage.getItem('defaultMicrophoneId'))
    setAudioOutputDeviceId(localStorage.getItem('audioOutputDeviceId'))
  }, [])

  useEffect(() => {
    window.addEventListener('set-settings-to-storage', setDevices)

    return () => window.removeEventListener('set-settings-to-storage', setDevices)
  }, [setDevices])

  const contextValue = {
    videoDeviceId,
    setVideoDeviceId: setVideoDeviceIdToStorage,
    audioOutputDeviceId,
    setAudioOutputDeviceId: setAudioOutputDeviceIdToStorage,
    setMicrophoneId: setMicrophoneIdToStorage,
    microphoneId,
    cameraResolution: outputCameraResolution,
    setCameraResolution: setCameraResolutionToStorage,
    receivedVideoResolution,
    setReceivedVideoResolution: setReceivedResolutionToStorage,
    isCameraEnabled,
    setIsCameraEnabled,
    isMicrophoneEnabled,
    setIsMicrophoneEnabled,
  }
  return (
    <CallSettingsContext.Provider value={contextValue}>
      { children }
    </CallSettingsContext.Provider>
  )
}

CallSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
