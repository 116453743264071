import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled'

import { usePersonalCallInfo } from 'hooks/usePersonalCallInfo'
import ChatArea from './ChatArea/ChatArea'

import './styles.scss'

export const VideoCallChat = ({ callDuration, agentId }) => {
  const callChatRef = useRef(null)
  const callInfo = usePersonalCallInfo(agentId)
  const userName = window.localStorage.getItem('userName')

  return (
    <div className="incoming-call-chat">
      <div className="header">
        <div className="header-title-wrap">
          <div>
            Chat with
            <span className="support-info">{` ${callInfo.name}`}</span>
          </div>
        </div>
      </div>
      <div className="store-location" />
      <div className="call-time-wrap">
        <PhoneEnabledIcon
          className="phone-icon"
          fontSize="small"
        />
        <div className="call-time">
          Ongoing call
          <span>{moment(callDuration * 1000).format('mm:ss')}</span>
        </div>
      </div>
      <ChatArea
        agentId={agentId}
        callInfo={callInfo}
        chatRef={callChatRef}
        name={userName}
        isCall
      />
    </div>
  )
}

VideoCallChat.propTypes = {
  callDuration: PropTypes.number.isRequired,
  agentId: PropTypes.string.isRequired,
}
