/*eslint-disable*/
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import heic2any from "heic2any"
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect'

export const getUuid = options => uuid(options)

export function hexToRgbA(hex, alpha) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`
  }
  throw new Error('Bad Hex')
}

export function setInnerHeight(name) {
  const k = 0.01
  const elements = document.getElementsByClassName(name)
  if (elements.length) {
    const el = elements[0]
    const vh = window.innerHeight * k
    el.style.setProperty('height', `100vh`)
    el.style.setProperty('height', `calc(var(--vh, 1vh) * 100)`)
    el.style.setProperty('--vh', `${vh}px`)
  }
}


/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  }
  return str
}

export function getTheDate(timestamp, format) {
  const time = timestamp * 1000
  const formatDate = format || 'MM-DD-YYYY'
  return moment(time).format(formatDate)
}

export function getCurrentTime(format) {
  const formatDate = format || 'HH:mm:sss'
  return moment().format(formatDate)
}

export function getTime(time, format) {
  const formatDate = format || 'HH:mm:sss'
  return moment(time).format(formatDate)
}

export function convertDateToTimeStamp(date, format) {
  const formatDate = format || 'DD, MMM YYYY'
  return moment(date).format(formatDate)
}

export function getAppLayout(url) {
  const location = url.pathname
  const path = location.split('/')
  return path[1]
}

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getTimeAgo = date => moment(date).fromNow()

export const convertTimeToTimestamp = (time, format) => {
  const formatDate = format || 'HH:mm'

  return moment(time, formatDate).format()
}

export const pluralize = (count, noun, suffix = 's') => (
  `${count} ${noun}${count !== 1 ? suffix : ''}`
)

export const getLocationHashValue = (key) => {
  const matches = location.hash.match(`${key}=([^&]*)`)
  return matches ? matches[1] : null
}

export const getParamsFromLocation = (key) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return key ? urlSearchParams.get(key) : Object.fromEntries(urlSearchParams.entries());
}

export const objectByString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const getDefaultDateFrom = () => {
  const date = new Date()

  date.setMonth(date.getMonth() - 1)

  return date
}

export const generateWidgetInstallCode = (userName, nmgId) => {
  return `
1) Copy the code below:
<div id=\"primeworks-customer-widget\" nmg-id=${nmgId}></div>
    <script defer src=\"widget.production.showroomvideo.chat/lib/build.widget.min.js?1b21612f13d4ac4d5a7c559e06eb8f0e517aaa59\"></script>
2) Open your application source code
3) In the source code, find and open the index.html file
4) Paste the copied code in <body></body> tag.Please mind that install code should be inside "body" tag.
Note: Widget will not affect your application styles.`
}

export const getNumberWithComma = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getPercentage = (currentValue, totalValue, noOfDecimal = 0) => Number(( currentValue / totalValue ) * 100).toFixed(noOfDecimal);

export const convertHoursToHHMM = (hoursInput) => {
  let hours = hoursInput,
    mins = 0;
  if (+hoursInput % 1 != 0) {
    hours = +String(hoursInput).split(".")[0];
    mins = +((hoursInput - hours) * 60).toFixed(0);
  }
  return `${hours < 10 ? "0" : ""}${hours}:${mins < 10 ? "0" : ""}${mins}`;
};

export  const convertHoursToDaysAndHHMM = (hoursInput) => {
  let days = 0;
  if (hoursInput >= 24) {
    const hoursInDays = hoursInput / 24;
    if (+hoursInDays % 1 != 0) {
      days = +String(hoursInDays).split(".")[0];
    } else {
      days = hoursInDays;
    }
  }
  const remainingHours = hoursInput - (days * 24)  ;
  return `${days}d ${convertHoursToHHMM(remainingHours)}`;
};

export const getBlobURL = (url) => {
  return new Promise(async (resolve, _) => {
    const res = await fetch(url);
    const blob = await res.blob();

    const conversionResult = await heic2any({
      blob,
      toType: "image/jpeg",
    });
    var reader = new FileReader();
    reader.readAsDataURL(conversionResult);
    reader.onloadend = function () {
      resolve(reader.result);
    }
  });
}

export const getDeviceTypeClassName = () => {
  switch (true) {
    case isIOS && isTablet:
      return 'ios-tablet-device'
    case isIOS && isMobile:
      return 'ios-mobile-device'
    case isAndroid && isTablet:
      return 'android-tablet-device'
    case isAndroid && isMobile:
      return 'android-mobile-device'
    default:
      return null
  }
}
