import { useEffect, useState } from 'react'
import { useFirebaseContext } from 'providers/FirebaseProvider'
import { AGENT_STATUSES } from 'constants/common'

export const useAgentAvailability = (userId) => {
  const { database } = useFirebaseContext()
  const rootRef = database.ref()
  const [availability, setAvailability] = useState('')
  const rootPath = rootRef.child(`users/${userId}`)

  useEffect(() => {
    if (userId) {
      rootPath.on('value', (snapshot) => {
        const value = snapshot.val()
        if (value?.availability) {
          setAvailability(value.availability)
        }
        if (!value) {
          setAvailability(AGENT_STATUSES.OFFLINE)
        }
      })
    }
  }, [userId])

  return availability
}
