import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

export const Avatar = ({
  userName = '',
  avatarSrc,
  isOnline,
  size,
  className,
}) => {
  const [firstName, lastName] = userName.split(' ')
  const userAbbreviation = `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase() ?? ''}`

  return (
    <div className={classNames('avatar-block', [className], [size])}>
      <div className={classNames('avatar', [size])}>
        {avatarSrc && (
          <img
            alt="user profile"
            className={classNames('img', [size])}
            src={avatarSrc}
          />
        )}
        {!avatarSrc && (
          <span className={classNames('user-abbreviation', [size])}>
            {userAbbreviation}
          </span>
        )}
      </div>
      {isOnline && (
        <span className={classNames('online-dot', [size])} />
      )}
    </div>
  )
}

Avatar.propTypes = {
  size: PropTypes.string,
  isOnline: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  avatarSrc: PropTypes.string,
  className: PropTypes.string,
}

Avatar.defaultProps = {
  size: 'md',
  isOnline: false,
  avatarSrc: '',
  className: '',
}
