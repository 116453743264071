/* eslint-disable no-param-reassign, no-return-assign */
import { useFirebaseContext } from 'providers/FirebaseProvider'
import { useMediaStreamContext } from 'providers/MediaStreamProvider'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isTablet, isMobileOnly } from 'react-device-detect'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Button from '@material-ui/core/Button'
import { setInnerHeight } from 'helpers/index'
import { usePeerContext } from 'providers/PeerProvider/PeerProvider'
import { useCallSettingsContext } from 'providers/CallSettingsProvider'
import Mic from '@material-ui/icons/Mic'
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined'
import { usePersonalCallInfo } from 'hooks/usePersonalCallInfo'
import { Controls } from './Controls/Controls'
import './style.scss'
import { VideoCallChat } from '../VideoCallChat/VideoCallChat'

export const VideoSpace = ({ agentId, setStep }) => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [time, setTime] = useState(0)
  const callInfo = usePersonalCallInfo(agentId)
  const { mediaStream } = useMediaStreamContext()
  const { database } = useFirebaseContext()
  const rootPath = database.ref()
  const callPath = rootPath.child(`organizations/${callInfo.organization}/personal-calls/${agentId}`)

  const {
    remoteVideoRef,
    localVideoRef,
    localScreenShareRef,
    isScreenSharing,
    isCallEnded,
    setForceUpdate,
    isOpponentCameraEnabled,
    answerCall,
    endCall,
  } = usePeerContext()

  const handleEndCall = () => {
    endCall()
    callPath.remove()
    setStep()
  }

  const { isCameraEnabled, isMicrophoneEnabled } = useCallSettingsContext()
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-shadow
      setTime(time => time + 1)
    }, 1000)
    return () => { clearTimeout() }
  }, [time])

  useEffect(() => {
    const setContainerHeight = () => isMobileOnly && setInnerHeight('video-space-wrapper-mobile')
    setContainerHeight()
    window.addEventListener('resize', setContainerHeight)
    return () => window.removeEventListener('resize', setContainerHeight)
  }, [])

  const toggleChat = () => {
    setIsChatOpen((state) => {
      setForceUpdate(state)
      return !state
    })
  }
  useEffect(() => {
    if (mediaStream && localVideoRef?.current && !isChatOpen) {
      mediaStream?.getVideoTracks().forEach(track => track.enabled = isCameraEnabled)
      mediaStream?.getAudioTracks().forEach(track => track.enabled = isMicrophoneEnabled)
      answerCall(mediaStream)
      localVideoRef.current.srcObject = mediaStream
    }
  }, [answerCall, mediaStream, isCameraEnabled, isMicrophoneEnabled, isChatOpen])

  const showVideo = !isTablet || (isTablet && !isChatOpen)

  return (
    <div className={classNames('video-space-wrapper', { 'video-space-wrapper-mobile': isMobileOnly })}>
      <div className="main-container">
        {isMobileOnly && (
          <div className={classNames('room-call-header', { 'is-blurred': isCameraEnabled })} />
        )}
        {!isCameraEnabled && isMobileOnly && (
          <div className="camera-disabled">
            <div>
              <img
                alt="avatar"
                src={callInfo.avatar}
              />
              <b>{callInfo.agentName}</b>
              <span>{isMicrophoneEnabled ? <Mic /> : <MicOffOutlinedIcon />}</span>
              <span><VideocamOffOutlinedIcon /></span>
            </div>
          </div>
        )}
        <div className={classNames(
          'video-container',
          { 'with-chat': isChatOpen },
        )}
        >
          {(!isOpponentCameraEnabled) && !isCallEnded && (
            <div className="avatar-container">
              {callInfo.avatar && (
                <img
                  alt="user profile"
                  className="large-size avatar-image"
                  src={callInfo.avatar}
                />
              )}
              {!callInfo.avatar && (
                <span
                  className="user-abbreviation large-size avatar-abbreviation"
                  title={callInfo?.name ?? 'Unknown'}
                >
                  {callInfo && callInfo.name ? callInfo.name[0]?.toUpperCase() : '&'}
                </span>
              )}
            </div>
          )}
          {isCallEnded && (
            <div className="call-ended-container">
              <span>Call ended</span>
            </div>
          )}
          {isScreenSharing && <div className="share-my-screen" />}
          {showVideo && (
            <div className="video-wrapper">
              <video
                ref={remoteVideoRef}
                className={classNames(
                  'remote-video',
                  { isDisabled: isCallEnded, 'd-none': (isScreenSharing || !isOpponentCameraEnabled) },
                )}
                autoPlay
                playsInline
              />
              <video
                ref={localVideoRef}
                className={classNames(
                  'local-video',
                  { isDisabled: !isCameraEnabled },
                )}
                autoPlay
                muted
                playsInline
              />
              <video
                ref={localScreenShareRef}
                className={classNames('screen-share', !isScreenSharing && 'd-none')}
                autoPlay
                muted
                playsInline
              />
            </div>
          )}
          {isChatOpen && callInfo.organization && (
            <VideoCallChat
              agentId={agentId}
              callDuration={time}
            />
          )}
        </div>
      </div>
      <div className="toolbar">
        <div className="toolbar-controls">
          <Controls
            callDuration={time}
            endCall={handleEndCall}
            isCallEnded={isCallEnded}
            toggleChat={toggleChat}
          />
        </div>
        {!isMobileOnly && (
          <div className="toolbar-chat">
            <Button onClick={toggleChat}>
              <ForumOutlinedIcon />
              <span className="chat-title">Chat</span>
              {isChatOpen && (<KeyboardArrowDownIcon />)}
              {!isChatOpen && (<KeyboardArrowUpIcon />)}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

VideoSpace.propTypes = {
  agentId: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
}
