import {
  isAndroid,
  isIOS,
  isMobile,
  isTablet,
} from 'react-device-detect'

export const getDeviceTypeClassName = () => {
  switch (true) {
    case isIOS && isTablet:
      return 'ios-tablet-device'
    case isIOS && isMobile:
      return 'ios-mobile-device'
    case isAndroid && isTablet:
      return 'android-tablet-device'
    case isAndroid && isMobile:
      return 'android-mobile-device'
    default:
      return null
  }
}
