import {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'

const defaultSpeaker = {
  deviceId: 'default',
  kind: 'audiooutput',
  label: 'Default audio output device',
}

export const useMediaDevices = () => {
  const [devices, setDevices] = useState([])

  const microphones = useMemo(() => devices.filter(device => device.kind === 'audioinput'), [devices])
  const speakers = useMemo(() => devices.filter(device => device.kind === 'audiooutput'), [devices])
  const cameras = useMemo(() => devices.filter(device => device.kind === 'videoinput'), [devices])

  const setMediaDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(mediaDevices => setDevices(mediaDevices))
  }, [])

  useEffect(() => {
    setMediaDevices()
  }, [setMediaDevices])

  return {
    microphones,
    speakers: speakers.length ? speakers : [defaultSpeaker],
    cameras,
    setMediaDevices,
  }
}
