/* eslint-disable no-return-assign,no-param-reassign */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useCallSettingsContext } from 'providers/CallSettingsProvider'
import classNames from 'classnames'
import { IconButton } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { isMobileOnly } from 'react-device-detect'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined'
import VideocamOutlined from '@material-ui/icons/VideocamOutlined'
import MicNoneIcon from '@material-ui/icons/MicNone'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined'
import ChatIcon from '@material-ui/icons/Chat'

import './style.scss'
import { usePeerContext } from 'providers/PeerProvider/PeerProvider'
import { SettingsModal } from '../../SettingsModal/SettingsModal'

export const Controls = ({ toggleChat, endCall, isCallEnded }) => {
  const {
    isMicrophoneEnabled,
    isCameraEnabled,
    setIsCameraEnabled,
    setIsMicrophoneEnabled,
  } = useCallSettingsContext()
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)

  const {
    localVideoRef,
    setVideoDeviceId,
    isScreenSharing,
    localScreenShareRef,
    toggleMyCamera,
  } = usePeerContext()

  const toggleCamera = () => {
    localVideoRef?.current?.srcObject?.getVideoTracks().forEach(track => track.enabled = !track.enabled)
    setIsCameraEnabled(state => !state)
    toggleMyCamera()
  }

  const toggleMicrophone = () => {
    setIsMicrophoneEnabled(state => !state)
    if (isScreenSharing) {
      localScreenShareRef.current.srcObject?.getAudioTracks().forEach(
        track => track.enabled = isMicrophoneEnabled,
      )
    } else {
      localVideoRef.current.srcObject?.getAudioTracks().forEach(
        track => track.enabled = !track.enabled,
      )
    }
  }

  const triggerSettingsModal = () => {
    setIsSettingsModalOpen(state => !state)
  }

  return (
    <div className="call-controls-wrapper">
      <IconButton
        aria-label="settings"
        className={classNames(
          'settings-button',
          'button',
          { deactivated: isCallEnded },
        )}
        onClick={triggerSettingsModal}
      >
        <SettingsIcon />
        {!isMobileOnly && (<span className="button-text">Settings</span>)}
      </IconButton>

      <Divider className="controls-divider" />

      <div className="call-buttons-wrapper">
        <IconButton
          className={classNames(
            { 'disabled-button': !isMicrophoneEnabled, deactivated: isCallEnded },
          )}
          onClick={toggleMicrophone}
        >
          {isMicrophoneEnabled
            ? <MicNoneIcon className="button-icon" />
            : <MicOffOutlinedIcon className="button-icon" />}
        </IconButton>
        <IconButton
          className={classNames(
            { 'disabled-button': !isCameraEnabled, deactivated: isCallEnded },
          )}
          onClick={toggleCamera}
        >
          {isCameraEnabled
            ? <VideocamOutlined className="button-icon" />
            : <VideocamOffOutlinedIcon className="button-icon" />}
        </IconButton>

        {isMobileOnly && (
          <IconButton
            onClick={toggleChat}
          >
            <ChatIcon />
          </IconButton>
        )}
      </div>

      <Divider className="controls-divider" />

      <IconButton
        className={classNames(
          'end-call-button',
          'button',
        )}
        onClick={endCall}
      >
        <ExitToAppIcon className="leave-icon" />
        {!isMobileOnly && (<span className="button-text">Leave</span>)}
      </IconButton>

      <SettingsModal
        closeModal={triggerSettingsModal}
        isOpen={isSettingsModalOpen}
        setVideoDeviceId={setVideoDeviceId}
        videoElementRef={localVideoRef}
      />
    </div>
  )
}

Controls.propTypes = {
  toggleChat: PropTypes.func.isRequired,
  endCall: PropTypes.func.isRequired,
  isCallEnded: PropTypes.bool.isRequired,
}
