import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { VideoCall } from './components/VideoCall/VideoCall'
import './App.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            component={VideoCall}
            path="/live-chat/:agentId"
          />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
