import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { useFirebaseContext } from 'providers/FirebaseProvider'

import { usePeerContext } from 'providers/PeerProvider/PeerProvider'
import { MessageType } from 'constants/chatConstants'
import { useClickOutside } from 'hooks/useClickOutside'
import { usePersonalCall } from 'hooks/usePersonalCall'

import { TabContainer } from './TabContainer'
import { useChatFileUpload } from '../useChatFileUpload'
import EmojiPicker from './EmojiPicker'

const ENTER_KEYCODE = 13

const Sender = ({
  chatScroll,
  userName,
  agentCallId,
  agentId,
  organization,
}) => {
  const [message, setMessage] = useState('')
  const [showEmoji, setShowEmoji] = useState(false)
  const personalCall = usePersonalCall(agentCallId, organization)
  const [, setChosenEmoji] = useState(null)
  const { database } = useFirebaseContext()
  const rootPath = database.ref()
  const messagesPath = rootPath.child(`organizations/${organization}/personal-calls/${agentCallId}/messages`)
  const { getInputProps, getRootProps } = useChatFileUpload({ chatScroll, agentId, messagesPath })
  const { isCallEnded } = usePeerContext()
  const isChatDisabled = isCallEnded || !personalCall?.agentId
  const sendMessage = (data) => {
    messagesPath.push().set(data)
  }
  const onEmojiClick = (event, { emoji }) => {
    setChosenEmoji(emoji)
    setMessage(prevMessage => prevMessage + emoji)
  }

  const currentRef = useClickOutside({
    onClick: () => setShowEmoji(false),
  })
  const onSubmitMessage = async (event) => {
    event.preventDefault()
    const sendingMessage = message.trim()
    if (sendingMessage) {
      // eslint-disable-next-line no-unused-vars
      const data = {
        content: sendingMessage,
        type: MessageType.TEXT,
        sender: 'customer',
        sentAt: new Date().getTime(),
      }

      sendMessage(data)
      setMessage('')
      setShowEmoji(false)
      chatScroll.current?.scrollToBottom()
    } else {
      setMessage('')
      setShowEmoji(false)
      chatScroll.current?.scrollToBottom()
    }
  }

  const handleKeyUpEvent = (evt) => {
    evt.preventDefault()
    if (evt.keyCode === ENTER_KEYCODE && evt.shiftKey === false && message) {
      onSubmitMessage(evt)
    }
  }

  const handleChangeEvent = (evt) => {
    if (evt.keyCode !== ENTER_KEYCODE) {
      setMessage(evt.target.value)
    }
    return false
  }

  return (
    <>
      {showEmoji && (
        <div ref={currentRef}>
          <EmojiPicker
            className="position-relative"
            onEmojiClick={onEmojiClick}
          />
        </div>
      )}
      <TabContainer>
        <div className={classNames(
          'sender-wrap d-flex bg-white pt-2',
        )}
        >
          <div className="pl-2 pt-2 sender-controls">
            <span
              className={classNames(
                'zmdi zmdi-mood mood-icon',
                { disabledSenderIcon: isChatDisabled },
              )}
              onClick={() => setShowEmoji(prevState => !prevState)}
            />
            <span
              className={classNames(
                'zmdi zmdi-attachment-alt attachment-icon',
                { disabledSenderIcon: isChatDisabled },
              )}
              {...getRootProps()}
            >
              <input
                disabled={isChatDisabled}
                {...getInputProps()}
                type="file"
              />
            </span>
          </div>
          <form
            className="msg-form"
            onSubmit={onSubmitMessage}
          >
            <textarea
              className="msg-input"
              disabled={isChatDisabled}
              placeholder={`Send your message to ${userName}...`}
              value={message}
              onChange={event => handleChangeEvent(event)}
              onKeyUp={handleKeyUpEvent}
            />
            {isMobile && (
              <a
                className={classNames(
                  'submit-button',
                  { 'btn-in-use': message },
                )}
                href=""
                onClick={onSubmitMessage}
              >
                Send
              </a>
            )}
          </form>
        </div>
      </TabContainer>
    </>
  )
}

Sender.propTypes = {
  chatScroll: PropTypes.shape({
    current: PropTypes.shape({
      scrollToBottom: PropTypes.func,
    }),
  }).isRequired,
  userName: PropTypes.string.isRequired,
  agentCallId: PropTypes.string.isRequired,
  agentId: PropTypes.string,
  organization: PropTypes.string,
}

Sender.defaultProps = {
  agentId: '',
  organization: '',
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Sender
