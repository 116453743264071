import PropTypes from 'prop-types'
import React, {
  createContext,
  useContext,
} from 'react'

import { database } from '../firebase'

const FirebaseContext = createContext({
  database: null,
})

export const useFirebaseContext = () => useContext(FirebaseContext)

export const FirebaseProvider = ({ children }) => {
  const contextValue = {
    database,
  }

  return (
    <FirebaseContext.Provider value={contextValue}>
      { children }
    </FirebaseContext.Provider>
  )
}

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
