export const MESSAGE_SENDER = {
  CUSTOMER: 'customer',
  AGENT: 'agent',
}

export const MESSAGES_TYPES = {
  FILE: 'file',
  DOC: 'doc',
  TEXT: 'text',
}

export const ENVIRONMENTS = {
  STAGING: 'staging',
  STAGING_STABLE: 'staging-stable',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
}

export const MESSAGE_BOX_SCROLL_DURATION = 400
