/* eslint-disable */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { MessageType } from 'constants/chatConstants'
import classnames from 'classnames'
import { getTime, getBlobURL } from 'helpers/index'
import { Avatar } from 'components/Avatar/Avatar'
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import './styles.scss'

const Message = ({ isEven, message }) => {
  const [imageUrl, setImageURL] = useState('');
  const isDocumentMessageType = message.type === MessageType.DOC
  const isTextMessageType = !message.type || message.type === MessageType.TEXT
  const isMediaMessageType = message.type === MessageType.FILE || message.attachments?.length

  const chatBubble = classnames(
    { 'chat-bubble': !isDocumentMessageType },
    { 'even bg-aqua': isEven && !isDocumentMessageType && !isMediaMessageType },
    { 'odd': !isEven && !isDocumentMessageType && !isMediaMessageType },
    { 'p-0': Boolean(message.media) },
  )

  /*  const isReadMessage = classnames('zmdi pl-2 fs-14',
      { 'zmdi-check-all text-pink': message?.isRead },
      { 'zmdi-check': !message?.isRead })*/

  const typeText = (<p className="message-text">{message.content}</p>)
  const file = message.file ?? {}

  const getDataURL = (fileName, url) => {
    if (fileName && url) {
      if (fileName.endsWith('.heic')) {
        getBlobURL(url).then(res => {
          setImageURL(res)
        });
      } else {
        setImageURL(url);
      }
    }
  }

  useEffect(() => {
    if(file?.contentType?.startsWith('image')) {
      getDataURL(file?.name, file?.url)
    }
  }, [imageUrl])

  const typeDoc = (
    <div className="type-doc">
      <a
        href={file?.url}
        target="_blank"
        download
      >
        {file?.name}
      </a>
    </div>
  )
  const typeMedia = (
    <div className="media-message-wrapper">
      <div className="type-media">
        {file?.contentType?.startsWith('image') && (
          <img
            alt={file?.name}
            src={file && file.url ? imageUrl : ''}
          />
        )}
        {file?.contentType?.startsWith('video') && (
          <div className="type-media-video">
            <video
              src={file.url}
              controls
            />
          </div>
        )}
      </div>
      {!isEven && (
        <a
          className="download-link"
          download
          href={file?.downloadUrl}
        >
          <span className="zmdi zmdi-download" />
        </a>
      )}
    </div>
  )

  return (
    <div className="chat-bubble-wrap">
      <div className={chatBubble}>
        {
          (isTextMessageType) && typeText
        }
        {
          (isDocumentMessageType) && typeDoc
        }
        {
          (isMediaMessageType) && typeMedia
        }
      </div>
      <div className={classNames(
        "chat-bubble-timer-container mt-1",
        {'justify-content-end': isEven}
      )}>
        <div className={classnames(
          'd-block font-xs text-muted time',
          { 'text-left': !isEven },
          { 'text-right': isEven },
        )}
        >
          {getTime(message.sentAt, 'HH:mm A')}
          {' '}
          {isEven && <i className="zmdi zmdi-check-all" />}
        </div>
      </div>
    </div>
  )
}
toolbar
const MessageBlock = ({
  isEven,
  message,
  userName,
                        agentAvatar,
  agentName,
}) => {

  const isEndCallMessage = message.type === MessageType.TEXT && message.channel === 'video'
  const isCallDeclined = message.content === 'Call declined'

  if (isEndCallMessage) {
    return (
      <div className={classNames(
        "call-ended-message",
        { "call-declined": isCallDeclined }
      )}>
        <PhoneDisabledIcon fontSize="small" />
        <span>{message.content}</span>
      </div>
    )
  }
  if (isEven) {
    return (
      <div className="user-message">
        <Avatar
          className="img-fluid rounded-circle ml-15 align-self-start"
          userName={userName}
        />
        <Message
          message={message}
          isEven={isEven}
        />
      </div>
    )
  }
  return (
    <div className="agent-message">
      <Avatar
        avatarSrc={agentAvatar}
        userName={agentName}
      />

      <Message
        message={message}
        isEven={isEven}
      />
    </div>
  )
}

Message.propTypes = {
  isEven: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    text: PropTypes.string,
    message: PropTypes.string,
    sent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string,
    file: PropTypes.shape({
      originalName: PropTypes.string,
      file_name: PropTypes.string,
      mime_type: PropTypes.string,
      url: PropTypes.string,
      file_size: PropTypes.number,
      contentType: PropTypes.string,
    }),
  }).isRequired,
}

MessageBlock.propTypes = {
  isEven: PropTypes.bool.isRequired,
  message: PropTypes.shape({}).isRequired,
  userName: PropTypes.string.isRequired
}

export default MessageBlock
