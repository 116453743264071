import { useMediaStreamContext } from 'providers/MediaStreamProvider'
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import Peer from 'peerjs'
import PropTypes from 'prop-types'
import { useCallSettingsContext } from 'providers/CallSettingsProvider'
import { peerConfig } from './config'

const PeerContext = createContext({
  answerCall: null,
  endCall: null,
  videoRef: null,
  isCallStarted: false,
  call: null,
  peer: null,
  retailerId: null,
  localStream: null,
  setLocalStream: null,
  isCallEnded: null,
  localVideoRef: null,
  customerPeerId: null,
})
export const usePeerContext = () => useContext(PeerContext)

const DESTROY_TIMEOUT = 100

export const PeerProvider = ({ children, customerPeerId }) => {
  const [call, setCall] = useState(null)
  const { mediaStream } = useMediaStreamContext()

  const {
    isCameraEnabled,
  } = useCallSettingsContext()
  const [isIncomingCall, setIsIncomingCall] = useState(false)
  const [isCallStarted, setIsCallStarted] = useState(false)
  const [remoteStream, setRemoteStream] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [localStream, setLocalStream] = useState(null)
  const [connection, setConnection] = useState(null)
  const [isCallEnded, setIsCallEnded] = useState(false)
  const [isOpponentCameraEnabled, setOpponentCameraEnabled] = useState(true)
  const [isMyCameraEnabled, setMyCameraEnabled] = useState(isCameraEnabled)

  const [myPeer, setPeer] = useState(null)
  const remoteVideoRef = useRef(null)
  const localVideoRef = useRef(null)

  const cleanUp = () => {
    setIsIncomingCall(false)
    setIsCallStarted(false)
    setIsCallEnded(false)
    setCall(null)
    setPeer(null)
  }

  useEffect(() => {
    if (customerPeerId) {
      const peer = new Peer(customerPeerId, peerConfig)

      peer.on('open', (id) => {
        console.log('peer open: ', id)
        setPeer(peer)
      })
      peer.on('call', (incomingCall) => {
        setCall(incomingCall)
        setIsIncomingCall(true)
      })

      peer.on('disconnected', () => {
        console.log('Peer disconnected')

        setTimeout(() => {
          if (!peer.destroyed) {
            peer.reconnect()
          }
        }, DESTROY_TIMEOUT)
      })

      peer.on('close', () => {
        setIsIncomingCall(false)
        console.log('Peer closed remotetly')
      })

      peer.on('error', (error) => {
        if (!peer.destroyed) {
          peer.reconnect()
        }
        console.log('peer error', error)
      })
    }
    return () => {
      if (customerPeerId) {
        cleanUp()
      }
    }
  }, [customerPeerId])

  useEffect(() => {
    if ((localVideoRef.current && remoteVideoRef.current) || forceUpdate) {
      localVideoRef.current.srcObject = mediaStream
    }
  }, [localStream, remoteStream, localVideoRef, remoteVideoRef, mediaStream, forceUpdate])

  useEffect(() => {
    myPeer?.on('connection', (dataConnection) => {
      setConnection(dataConnection)
    })

    return () => {
      if (myPeer) {
        myPeer.destroy()
      }
    }
  }, [myPeer])

  useEffect(() => {
    connection?.on('open', () => {
      connection.send('connected')
      connection.send({ isCameraEnabled: isMyCameraEnabled })
    })

    connection?.on('data', (data) => {
      if (data !== 'connected') {
        setOpponentCameraEnabled(data.isCameraEnabled)
      }
    })

    connection?.on('close', () => {
      setIsCallEnded(true)
    })
  }, [connection, isMyCameraEnabled])

  const toggleMyCamera = () => {
    if (connection) {
      connection.send({ isCameraEnabled: !isMyCameraEnabled })
    }
    setMyCameraEnabled(!isMyCameraEnabled)
  }

  const answerCall = (stream) => {
    setIsCallStarted(true)
    connection?.send({ isCameraEnabled })
    call?.answer(stream)
  }

  useEffect(() => {
    call?.on('stream', (stream) => {
      setRemoteStream(stream)
    })

    call?.on('close', () => {
      localStream?.getTracks().forEach(track => track.stop())
    })
  }, [call, localStream])

  useEffect(() => {
    if (remoteStream && (remoteVideoRef?.current || forceUpdate)) {
      remoteVideoRef.current.srcObject = remoteStream
    }
  }, [remoteStream, forceUpdate, remoteVideoRef])

  const contextValue = {
    answerCall,
    endCall: cleanUp,
    isIncomingCall,
    remoteVideoRef,
    setForceUpdate,
    isCallStarted,
    call,
    peer: myPeer,
    localStream,
    setLocalStream,
    isCallEnded,
    isOpponentCameraEnabled,
    localVideoRef,
    toggleMyCamera,
    customerPeerId,
  }
  return (
    <PeerContext.Provider value={contextValue}>
      {children}
    </PeerContext.Provider>
  )
}

PeerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  customerPeerId: PropTypes.string.isRequired,
}
