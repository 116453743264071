import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import './styles.scss'

const ITEM_TEXT_MAX_LENGTH = 70

export const SettingsSelect = ({
  onChange,
  options,
  label,
  valueField,
  inputProps,
}) => {
  const getItemText = (text) => {
    if (text.length > ITEM_TEXT_MAX_LENGTH) {
      return `${text.substring(0, ITEM_TEXT_MAX_LENGTH) }...`
    }
    return text
  }

  return (
    <div className="select-wrapper">
      <Typography className="settings-select-label">{label}</Typography>
      <FormControl
        className="settings-form-control"
        variant="outlined"
      >
        <Select
          className="settings-select"
          value={inputProps.value}
          onChange={onChange}
        >
          {options.map(option => (
            <MenuItem
              key={option[valueField]}
              value={option[valueField]}
            >
              {/* eslint-disable-next-line no-magic-numbers */}
              {getItemText(option.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>

  )
}

SettingsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    deviceId: PropTypes.string,
  })).isRequired,
  label: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  inputProps: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
}
