/* eslint-disable max-lines */
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined'
import React, { useRef, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Mic from '@material-ui/icons/Mic'

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined'
import VideocamOutlined from '@material-ui/icons/VideocamOutlined'
import { useCallSettingsContext } from 'providers/CallSettingsProvider'
import { useMediaStreamContext } from 'providers/MediaStreamProvider'
import { useFirebaseContext } from 'providers/FirebaseProvider'

import { useAgentAvailability } from 'hooks/useAgentAvailability'
import { usePersonalCallInfo } from 'hooks/usePersonalCallInfo'
import { useMediaDevices } from './useMediaDevices'
import { SettingsModal } from './SettingsModal/SettingsModal'
import './style.scss'

const useStyles = makeStyles(() => ({
  root: props => ({
    backgroundColor: props.active ? 'transparent' : 'red',
    color: 'white',
    border: props.active ? '1px solid white' : '',
  }),
}))

export const WaitingRoom = ({
  agentId,
  setStep,
  customerPeerId,
}) => {
  const callInfo = usePersonalCallInfo(agentId)
  const { cameras, microphones, setMediaDevices } = useMediaDevices()
  const { mediaStream } = useMediaStreamContext()
  const availability = useAgentAvailability(callInfo.user)
  const localVideoRef = useRef(null)
  const [userName, setUserName] = useState(null)
  const [errorText, setErrorText] = useState(null)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [personalCall, setPersonalCall] = useState({})
  const [isAgentBusy, setIsAgentBusy] = useState(false)
  const isAgentUnavailable = availability !== 'available'

  const { database } = useFirebaseContext()
  const rootPath = database.ref()
  const callPath = rootPath.child(`organizations/${callInfo.organization}/personal-calls/${agentId}`)
  const isJoinButtonDisabled = !userName || isAgentBusy || isAgentUnavailable
  const warningText = isAgentUnavailable ? 'Agent is unavailable' : 'Agent is on another call'

  const {
    setIsCameraEnabled,
    isCameraEnabled,
    isMicrophoneEnabled,
    setIsMicrophoneEnabled,
  } = useCallSettingsContext()

  const styledCamera = useStyles({ active: isCameraEnabled })
  const styledMic = useStyles({ active: isMicrophoneEnabled })
  const styledSettings = useStyles({ active: true, height: 55 })

  const mediaPermissionsDenied = (Boolean(cameras.length) && (!cameras[0].deviceId || !cameras[0].label))
    || (Boolean(microphones.length) && (!microphones[0].deviceId || !microphones[0].label))

  const toggleMicrophone = () => {
    setIsMicrophoneEnabled(state => !state)
  }

  const toggleVideo = () => {
    setIsCameraEnabled(state => !state)
  }

  const triggerSettingsModal = () => {
    setIsSettingsModalOpen(state => !state)
  }

  useEffect(() => {
    if (mediaStream || mediaPermissionsDenied) {
      setMediaDevices()
    }
  }, [mediaStream, mediaPermissionsDenied, setMediaDevices])

  useEffect(() => {
    if (mediaStream && localVideoRef.current && isCameraEnabled) {
      localVideoRef.current.srcObject = mediaStream
    }
  }, [mediaStream, isCameraEnabled])

  useEffect(() => {
    if (mediaStream && localVideoRef.current?.srcObject) {
      localVideoRef.current.srcObject.getVideoTracks()[0].enabled = isCameraEnabled
      localVideoRef.current.srcObject.getAudioTracks()[0].enabled = isMicrophoneEnabled
    }
  }, [mediaStream, isCameraEnabled, isMicrophoneEnabled, localVideoRef])

  useEffect(() => {
    callPath.on('value', (snap) => {
      const value = snap.val()
      if (!value) {
        setIsAgentBusy(false)
      }
      if (value) {
        setPersonalCall(value)
      }
      if (value && value.customerId !== customerPeerId) {
        setIsAgentBusy(true)
      }
    })
  }, [callInfo])

  useEffect(() => {
    if (personalCall.customerId === customerPeerId) {
      callPath.onDisconnect().remove()
    }
  }, [callPath, customerPeerId])

  useEffect(() => {
    rootPath.onDisconnect().remove()
  })
  const handleInputChange = (event) => {
    const name = event.target.value
    setUserName(name.trim())
  }
  const handleBLur = () => {
    if (!userName?.length) {
      setErrorText('Name is required')
    }
  }

  useEffect(() => {
    if (userName !== null && !userName.length) {
      setErrorText('Name is required')
    }
    if (userName?.length) {
      setErrorText('')
    }
  }, [userName])

  const handleJoinCall = () => {
    callPath.set({ customerId: customerPeerId, customerName: userName })
    window.localStorage.setItem('userName', userName)
    setStep()
  }
  return (
    <div className="waiting-room-wrapper">
      {isSettingsModalOpen && (
        <SettingsModal
          closeModal={triggerSettingsModal}
          isOpen={isSettingsModalOpen}
        />
      )}

      <div className="waiting-screen">
        {!mediaPermissionsDenied && (
          <>
            <img
              alt="organization-logo"
              className="organization-logo"
              src={callInfo.logo}
            />
            <span className="title-question fw-bold pb-10">Join a video call</span>
            <div className="name-input-wrapper">
              <TextField
                className="name-input"
                error={Boolean(errorText)}
                helperText={errorText}
                placeholder="Enter your name"
                size="small"
                variant="outlined"
                onBlur={handleBLur}
                onChange={handleInputChange}
              />
            </div>
          </>
        )}
        <section className="settings-screen">
          {!mediaPermissionsDenied && (
            <>
              <div className="settings-screen-camera">
                <video
                  ref={localVideoRef}
                  className={classNames(
                    'video-preview',
                    { hidden: isSettingsModalOpen },
                  )}
                  autoPlay
                  muted
                  playsInline
                />
              </div>
              <div className="settings-controls">
                <Fab
                  className={styledSettings.root}
                  variant="extended"
                  onClick={triggerSettingsModal}
                >
                  <SettingsOutlinedIcon />
                  {!isMobileOnly && <span className="button-text">Settings</span>}
                </Fab>
                {!isMobileOnly && <div className="settings-controls-delimiter" />}
                <Fab
                  className={classNames('mx-20', styledMic.root)}
                  onClick={toggleMicrophone}
                >
                  { isMicrophoneEnabled ? <Mic /> : <MicOffOutlinedIcon /> }
                </Fab>
                <Fab className={styledCamera.root}>
                  { isCameraEnabled
                    ? <VideocamOutlined onClick={toggleVideo} />
                    : <VideocamOffOutlinedIcon onClick={toggleVideo} />}
                </Fab>
              </div>
            </>
          )}
          {mediaPermissionsDenied && (
            <div className="waiting-screen-error">
              <span>
                Сannot access your microphone and camera - please give permission in your browser setting.
              </span>
            </div>
          )}
        </section>
        <section className="customer-section">
          {(isAgentBusy || isAgentUnavailable) && (<span className="agent-busy-text">{warningText}</span>)}
          <div className="join-button-wrapper">
            <Button
              className={classNames(
                'btn-join-now',
                { isJoinButtonDisabled: 'disabled' },
              )}
              disabled={mediaPermissionsDenied || isJoinButtonDisabled}
              onClick={handleJoinCall}
            >
              Join now
            </Button>
          </div>
        </section>
      </div>
    </div>
  )
}

WaitingRoom.propTypes = {
  setStep: PropTypes.func.isRequired,
  agentId: PropTypes.string.isRequired,
  customerPeerId: PropTypes.string.isRequired,
}
