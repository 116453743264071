export const developmentConfig = {
  apiKey: 'AIzaSyCK5vU88uz-W_QvmyStB39QQfstt4Wy5Sg',
  authDomain: 'messaging-platform-dev-1.firebaseapp.com',
  databaseURL: 'https://messaging-platform-dev-1-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'messaging-platform-dev-1',
  storageBucket: 'messaging-platform-dev-1.appspot.com',
  messagingSenderId: '908733921883',
  appId: '1:908733921883:web:e304aea30510db747fdd7e',
}

export const stagingConfig = {
  apiKey: 'AIzaSyBlhRN4UAqhJjvL9FslJjTcyxsE43qAybU',
  authDomain: 'messaging-platform-staging-1.firebaseapp.com',
  databaseURL: 'https://messaging-platform-staging-1-default-rtdb.firebaseio.com',
  projectId: 'messaging-platform-staging-1',
  storageBucket: 'messaging-platform-staging-1.appspot.com',
  messagingSenderId: '898348287073',
  appId: '1:898348287073:web:9f6df39bc412437f941620',
}

export const productionConfig = {
  apiKey: 'AIzaSyBE9wpWNITyM_jgS9JOvUOTWK42qmW0MBc',
  authDomain: 'messaging-platform-prod-1.firebaseapp.com',
  databaseURL: 'https://messaging-platform-prod-1-default-rtdb.firebaseio.com',
  projectId: 'messaging-platform-prod-1',
  storageBucket: 'messaging-platform-prod-1.appspot.com',
  messagingSenderId: '731594167950',
  appId: '1:731594167950:web:55a38bb8e170860e775bdf',
}

export const stagingStableConfig = {
  apiKey: 'AIzaSyB5C6cX5VNjVjleO1FS1BQqm0eiOfNxJVI',
  authDomain: 'messaging-platform-stable-1.firebaseapp.com',
  projectId: 'messaging-platform-stable-1',
  storageBucket: 'messaging-platform-stable-1.appspot.com',
  messagingSenderId: '1038908867736',
  appId: '1:1038908867736:web:534952a697df1dd8f2bced',
}
