import { useState, useEffect } from 'react'
import { useCallSettingsContext } from 'providers/CallSettingsProvider'

export const useUserMedia = () => {
  const [mediaStream, setMediaStream] = useState(null)

  const {
    videoDeviceId,
    cameraResolution,
    microphoneId,
  } = useCallSettingsContext()

  useEffect(() => {
    const enableStream = () => {
      navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: videoDeviceId ? {
            exact: videoDeviceId,
          } : undefined,
          width: { ideal: cameraResolution?.width },
          height: { ideal: cameraResolution?.height },
        },
        audio: {
          deviceId: microphoneId ? {
            exact: microphoneId,
          } : undefined,
        },
      }).then(stream => setMediaStream(stream))
    }
    if (!mediaStream || !mediaStream.active) {
      enableStream()
    } else {
      return () => {
        mediaStream.getTracks().forEach((track) => {
          track.stop()
        })
      }
    }
    return mediaStream?.getTracks().forEach((track) => {
      track.stop()
    })
  }, [videoDeviceId, microphoneId, mediaStream, cameraResolution])

  return { mediaStream }
}
