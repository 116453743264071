import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { getEnv } from 'utils/getEnv'
import { ENVIRONMENTS } from '../constants'
import {
  developmentConfig,
  productionConfig,
  stagingConfig,
  stagingStableConfig,
} from './config'

const getFirebaseConfig = () => {
  switch (getEnv()) {
    case ENVIRONMENTS.DEVELOPMENT:
      return developmentConfig
    case ENVIRONMENTS.PRODUCTION:
      return productionConfig
    case ENVIRONMENTS.STAGING:
      return stagingConfig
    case ENVIRONMENTS.STAGING_STABLE:
      return stagingStableConfig
    default:
      return developmentConfig
  }
}

const firebaseConfig = getFirebaseConfig()

firebase.initializeApp(firebaseConfig)

const database = firebase.database()

export {
  database,
}
