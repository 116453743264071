import { useState, useEffect } from 'react'

import { useFirebaseContext } from 'providers/FirebaseProvider'

export const usePersonalCall = (agentId, organization) => {
  const [personalCall, setPersonalCall] = useState({})

  const { database } = useFirebaseContext()

  const rootPath = database.ref()

  useEffect(() => {
    const callPath = rootPath.child(`organizations/${organization}/personal-calls/${agentId}`)

    callPath.on('value', (snap) => {
      const value = snap.val()
      if (value) {
        setPersonalCall(value)
      }
    })
  }, [agentId, organization])

  return personalCall
}
