import { useUserMedia } from 'providers/PeerProvider/useUserMedia'
import React, {
  createContext,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

const MediaStreamContext = createContext({
  mediaStream: null,
})
export const useMediaStreamContext = () => useContext(MediaStreamContext)

export const MediaStreamProvider = ({ children }) => {
  const { mediaStream } = useUserMedia()
  const contextValue = {
    mediaStream,
  }
  return (
    <MediaStreamContext.Provider value={contextValue}>
      { children }
    </MediaStreamContext.Provider>
  )
}

MediaStreamProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
