import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'
import PropTypes from 'prop-types'

const heightButton = 40
const borderButton = 2
const styles = {
  root: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: `${heightButton - borderButton}px`,
    padding: '0 25px',
    borderRadius: 4,
    border: '1px solid',
    color: '#0D172A',
  },
  contained: {
    boxShadow: 'none',
  },
  label: {
    textTransform: 'capitalize',
  },
  outlinedPrimary: {
    borderColor: '#0D172A',
    '&:hover': {
      boxShadow: 'none',
      borderColor: '#D8DADD',
      backgroundColor: '#D8DADD',
      color: '#0D172A',
    },
  },
  containedPrimary: {
    backgroundColor: '#FF7A59',
    color: '#FFFFFF',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#FF7A59',
      color: '#FFFFFF',
    },
  },
}

const Button = ({
  className, title, icon: Icon, children, ...restProps
}) => (
  <MuiButton
    {...restProps}
    className={classNames('button', className)}
  >
    {Icon && <span className="icon-wrapper"><Icon /></span>}
    {' '}
    {title || children}
  </MuiButton>
)

Button.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
  icon: PropTypes.elementType,
}

Button.defaultProps = {
  title: '',
  className: '',
  children: '',
  icon: null,
}

export default withStyles(styles)(Button)
