import { useEffect, useState } from 'react'
import { useFirebaseContext } from 'providers/FirebaseProvider'

export const useMessages = ({ organization, agentId }) => {
  const { database } = useFirebaseContext()
  const [messages, setMessages] = useState([])
  const rootPath = database.ref()
  const messagesPath = rootPath.child(`organizations/${organization}/personal-calls/${agentId}/messages`)
  const messageComparator = (currentMessage, nextMessage) => currentMessage.sent - nextMessage.send

  useEffect(() => {
    messagesPath.on('value', (snap) => {
      const value = snap.val()
      if (value) {
        const snapMessages = Object.keys(value).map(key => ({
          messageId: key,
          ...value[key],
        })).sort(messageComparator)
        setMessages(snapMessages)
      }
    })
  }, [organization])

  return messages
}
