import { useState } from 'react'

import Api from 'api'
import { toast } from 'react-toastify'
import { ALLOWED_FILE_TYPES } from 'constants/common'

const BYTES_IN_MB = 1024
export const useFileUpload = (allowedTypes) => {
  const [isLoading, setIsLoading] = useState(false)

  const uploadFile = ({
    maxFileSize,
    allowedFileTypes = allowedTypes ?? ALLOWED_FILE_TYPES,
    file,
    uploadPath,
    customerChannel,
    callback,
  }) => {
    setIsLoading(true)
    const getFileExtension = () => {
      const fileNameArray = file.path.split('.')

      if (!file.type.length || fileNameArray[fileNameArray.length - 1] === 'jfif') {
        return file.path.split('.')[fileNameArray.length - 1]
      }
      return file.type.split('/')[1]
    }

    if (file.size > maxFileSize) {
      toast.error(`File exceeds ${maxFileSize / (BYTES_IN_MB * BYTES_IN_MB).toFixed(2)} MB`)
      setIsLoading(false)
      return
    }

    if (!allowedFileTypes.includes(getFileExtension())) {
      toast.error(`Unsupported file format. ${allowedFileTypes.join(', ')} only`)
      setIsLoading(false)
      return
    }
    const fileData = new FormData()
    fileData.append('file', file)
    Api.post(`${process.env.REACT_APP_FS_API}/${uploadPath}`, fileData)
      .then((result) => {
        setIsLoading(false)
        callback(result, customerChannel)
      })
  }

  return {
    uploadFile,
    isLoading,
  }
}
