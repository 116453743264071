import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { MessageType } from 'constants/chatConstants'
import { useFileUpload } from 'hooks/useFileUpload'

const MAX_FILE_SIZE = 15728640 // 15Mb
export const useChatFileUpload = ({ chatScroll, agentId, messagesPath }) => {
  const { uploadFile } = useFileUpload()

  const sendMessage = (data) => {
    messagesPath.push().set(data)
  }
  const handleSendFileMessage = (result) => {
    const file = result.data?.files[0] ?? {}
    const data = {
      file,
      sentAt: Date.now(),
      type: MessageType.FILE,
      downloadUrl: file.downloadUrl,
      sender: 'customer',
    }
    sendMessage(data)
    chatScroll.current?.scrollToBottom()
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadFile({
        file,
        uploadPath: `personal-calls/${agentId}`,
        maxFileSize: MAX_FILE_SIZE,
        callback: handleSendFileMessage,
      })
    })
  }, [agentId])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
  })

  return {
    onDrop,
    getRootProps,
    getInputProps,
  }
}
