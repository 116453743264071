/* eslint-disable camelcase, no-unused-vars */
import { useMessages } from 'hooks/useMessages'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Scrollbars } from 'react-custom-scrollbars'

import PropTypes from 'prop-types'
import MessageBlock from '../MessageBlock'
import Sender from '../Sender'
import '../styles.scss'

function ChatArea({
  chatRef,
  agentId,
  name,
  callInfo,
}) {
  const { avatar, organization, name: agentName } = callInfo
  const messages = useMessages({ agentId, organization })
  // eslint-disable-next-line no-magic-numbers
  const height = `calc(100vh - ${145}px)!important`
  return (
    <div className="chat-main-body">
      <Scrollbars
        ref={chatRef}
        className="rct-scroll"
        style={{ height }}
        autoHide
      >
        <div className="chat-body">
          {messages.map(message => (
            <MessageBlock
              key={message.messageId}
              adminPhotoUrl="admin_photo_url"
              agentAvatar={avatar}
              agentName={agentName}
              isEven={message.sender === 'customer'}
              message={message}
              userName={name}
            />
          ))}
        </div>
      </Scrollbars>
      <div className="chat-footer">
        <Sender
          agentCallId={agentId}
          agentId={callInfo.user}
          chatScroll={chatRef}
          organization={organization}
          userName={name}
        />
      </div>
    </div>
  )
}

ChatArea.propTypes = {
  agentId: PropTypes.string.isRequired,
  chatRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollToBottom: PropTypes.func,
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  callInfo: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
    organization: PropTypes.string,
    user: PropTypes.string,
  }).isRequired,
}

export default withRouter(ChatArea)
