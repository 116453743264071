import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

export const TabContainer = ({ children }) => (
  <Typography
    className="tab-container"
    component="div"
    // todo CALL CONSTANTS CORRECTLY
    /* eslint-disable-next-line no-magic-numbers */
    style={{ padding: 8 * 3 }}
  >
    {children}
  </Typography>
)

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
