import { useState, useEffect } from 'react'
import Api from 'api'

export const usePersonalCallInfo = (agentId) => {
  const [callInfo, setCallInfo] = useState({})

  useEffect(() => {
    if (agentId) {
      Api.get(`${process.env.REACT_APP_API_BASE_URL}/users/call/${agentId}`)
        .then(res => setCallInfo(res.data))
    }
  }, [agentId])

  return callInfo
}
