import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CallSettingsProvider } from 'providers/CallSettingsProvider'
import { MediaStreamProvider } from 'providers/MediaStreamProvider'
import { FirebaseProvider } from 'providers/FirebaseProvider'
import { PeerProvider } from 'providers/PeerProvider/PeerProvider'
import { getDeviceTypeClassName } from 'helpers'
import classNames from 'classnames'
import { Button } from '@material-ui/core'

import { v4 as uuid } from 'uuid'
import { VideoSpace } from './VideoSpace/VideoSpace'
import { WaitingRoom } from './WaitingRoom'
import './style.scss'

export const VideoCall = ({ match }) => {
  const [step, setStep] = useState(0)
  const [customerPeerId, setCustomerPeerId] = useState('')
  const agentId = match.params.agentId
  const deviceTypeClassName = getDeviceTypeClassName()

  useEffect(() => {
    setCustomerPeerId(uuid())
  }, [])

  return (
    <MediaStreamProvider>
      <FirebaseProvider>
        <CallSettingsProvider>
          <PeerProvider
            agentId={agentId}
            customerPeerId={customerPeerId}
          >
            <div
              className={classNames(deviceTypeClassName, 'call-room')}
            >
              <div className="call-room-content">
                {step === 0 && (
                  <WaitingRoom
                    agentId={match.params.agentId}
                    customerPeerId={customerPeerId}
                    setStep={() => setStep(1)}
                  />
                )}
                {step === 1 && (
                  <VideoSpace
                    agentId={match.params.agentId}
                    setStep={() => setStep(2)}
                  />
                )}
                {step === 2 && (
                  <div className="call-ended-wrapper">
                    <span>Thank you for your call</span>
                    <Button
                      className="callback-button"
                      onClick={() => {
                        setStep(0)
                        setCustomerPeerId(uuid())
                      }}
                    >
                      Call back
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </PeerProvider>
        </CallSettingsProvider>
      </FirebaseProvider>
    </MediaStreamProvider>
  )
}

VideoCall.propTypes = {
  call: PropTypes.shape({}),
  localStream: PropTypes.shape({
    getTracks: PropTypes.func,
    getAudioTracks: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      agentId: PropTypes.string,
    }),
  }).isRequired,
}

VideoCall.defaultProps = {
  call: {},
  localStream: {},
}
